<template>
  <div>
    <div class="info cost">
      <b-container>
        <b-row>
          <b-col cols="12">
            <h2 id="tarieven">Tarieven Spring Talentbegeleiding</h2>
          </b-col>
          <b-col xs="12">
            <div>
              <table class="table">
                <tbody>
                  <tr v-for="item in items" :key="item[0]">
                    <td v-html="item[0]"></td>
                    <td style="width:10%;">&euro; {{item[1]}},-</td>
                  </tr>
                </tbody>
              </table>
              <h3 id="ukiq-cost">Tarieven ukIQ/PeuterSpring</h3>
              <table class="table">
                <tbody>
                <tr v-for="item in ukiq" :key="item[0]">
                  <td v-html="item[0]"></td>
                  <td style="width:10%;">&euro; {{item[1]}},-</td>
                </tr>
                </tbody>
              </table>
              <p>
                *Eindverslag ukIQ bevat de feitelijke observaties per ontwikkelingsgebied, het functioneren, het
                groeiproces, de sterke kanten en ontwikkelkanten, algemeen beeld en bevindingen plus handvatten en
                adviezen voor het vervolg(school) en voor thuis.
              </p>
              <h3 id="totale-aanbod">
                N.B.
              </h3>

              <p>
                Bij het totale aanbod geldt: korte contacten tussendoor(&lt;15 minuten)  met ouders en met school of andere
                professionals zijn bij het aanbod inbegrepen.
              </p>

              <p>
                Voor afspraken op locatie anders dan in Koudekerk aan den Rijn, Hazerswoude Rijndijk of Alphen aan den
                Rijn worden reiskosten in rekening gebracht van 0,23 euro per kilometer. Voor enkele reistijd langer dan
                20 minuten reken ik daarbij 0,45 euro per minuut extra.
              </p>

              <p>Alle bedragen zijn vrijgesteld van BTW.</p>
            </div>
          </b-col>
        </b-row>
      </b-container>
    </div>
  </div>
</template>

<script>
export default {
  name: "Cost",
  data() {
    return {
      items: [
          ['Uurtarief voor gesprekken, coaching, begeleiding', '75'],
          ['Schoolobservatie van twee uur met verslag', '175'],
          ['Observatie KDV of PSZ van twee uur met verslag', '175'],
      ],
      ukiq: [
          ['Per serie van zes keer', '165'],
          ['Observatie en intake aan huis vooraf', '75'],
          ['Eenmalig: uitgebreid eindverslag* voor school', '150'],
      ]
    }
  }
}
</script>

<style>
  .hidden_header {
    display:none;
  }

  .cost {
    overflow-y: scroll;
  }

  #ukiq-cost {
    margin-top: 35px;
  }

  #totale-aanbod {
    margin-top: 36px;
  }
</style>
